export default [
  {
    id: 1,
    slug: 'ezstay',
    banner:
      'https://images.unsplash.com/photo-1570215171323-4ec328f3f5fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
    title: 'eZStay',
    categories: ['mobile'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://images.unsplash.com/photo-1570215171323-4ec328f3f5fa?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80',
      absoluteUrl: ''
    },
    proposeSolutions:
      'When receiving the idea from client, our development teams start building an ecosystem of a mobile and web apps base on current TMS.',
    stack: `<ul>
      <li>Android, iOS</li>
      <li>Firebase</li>
      <li>Mobile app</li>
      </ul>`,
    market: `<ul>
    <li>Vietnam</li>
    </ul>`,
    shortDescription:
      'The Vietnamese booking-homestay (Vacation Rentals, Homes, Apartments and room) platform. ',
    description:
      'The Vietnamese booking-homestay (Vacation Rentals, Homes, Apartments and room) platform. <br />Live like natives. <br />A unique character in each homestay. <br />eZStay is a Start Up product Vietnamese booking-homestay platform, which allows for an associate system between hosts and guests. <br />',
    links: []
  },
  {
    id: 2,
    slug: 'franksalt',
    banner:
      'https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    title: 'FrankSalt',
    categories: ['web'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://images.unsplash.com/photo-1560448204-e02f11c3d0e2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development teams join to add new added values, optimize the features and improve the system',
    stack: `<ul>
      <li>.NET</li>
      <li>GraphQL</li>
      <li>Angular</li>
      </ul>`,
    market: `<ul>
    <li>Malta</li>
    </ul>`,
    shortDescription: "Malta's leading portal for property for sale and to let.",
    description:
      "<p><a className='company-link' href='https://franksalt.com.mt/'>Malta's leading portal</a> for property for sale and to let. </p><p>Frank Salt Real Estate was established in 1969 and has become Malta’s oldest, biggest family-owned and managed real estate company. With branches in both Malta and Gozo, the company specialises in the sale and letting of residential and commercial properties but also offers property management and home interiors services.</p><p>With 20 branches and just under 200 professional real estate agents, the company offers the widest selection of properties for sale and to let in Malta and Gozo. Long and short-term letting options cater to the tourism, foreign and local markets. Malta’s leading real estate company also offers dedicated services for Property Management and Home Interiors Consultancy.</p>",
    links: [
    ]
  },
  {
    id: 3,
    slug: 'container_arrangement_optimizing_system',
    banner:
      'https://images.unsplash.com/photo-1604284841484-ee576f12275c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1761&q=80',
    title: 'Container Arrangement Optimizing System',
    categories: ['web', 'mobile'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://images.unsplash.com/photo-1604284841484-ee576f12275c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1761&q=80',
      absoluteUrl: ''
    },
    proposeSolutions:
      'When receiving the idea from client, our development teams start building an ecosystem of a mobile and web apps base on current TMS.',
    stack: `<ul>
      <li>ReactJS</li>
      <li>.NET</li>
      <li>Flutter</li>
      </ul>`,
    market: `<ul>
    <li>Vietnam</li>
    </ul>`,
    shortDescription:
      'CAOS is developed to support Terminal Management System (TMS) at the Ho Chi Minh international port.',
    description: `<p>CAOS is developed to support Terminal Management System (TMS) at the Ho Chi Minh international port. Due to the unique characteristics of optimal arrangement at the port, the customer wants to improve the features that are currently unavailable and outdated in TMS. CAOS is built to develop software separately with add-on modules to make port management more convenient through new technologies that TMS does not support (due to technology obsolescence)</p>
      <p>CAOS includes:</p>
      <ul>
      <li>Mobile app, Web app: diverse data entry on different platforms</li>
      <li>Module access directly to the database of TMS</li>
      <li>Containers arrangement optimizing feature which help reduce the workload at the port and improve operation team performance</li>
      </ul>`,
    links: []
  },
  {
    id: 4,
    slug: 'early_parrot',
    banner:
      'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1115&q=80',
    title: 'Early Parrot',
    categories: ['web'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://images.unsplash.com/photo-1460925895917-afdab827c52f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1115&q=80',
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development teams join to work on the ecosystem of web apps. This is a product from the client and it keeps updating and growing with new features, improves.',
    stack: `<ul>
      <li>AngularJS</li>
      <li>NodeJS</li>
      </ul>`,
    market: `<ul>
    <li>Malta</li>
    </ul>`,
    shortDescription:
      'Referral marketing leverages the power of word-of-mouth, by rewarding your existing audience in exchange for a referral. Referrers share their unique link on several channels such as Facebook, Twitter, LinkedIn, Instagram, Whatsapp and Facebook Messenger. Referrers are then rewarded for spreading the word and getting new customers and subscribers.',
    description: `<p>Referral marketing leverages the power of word-of-mouth, by rewarding your existing audience in exchange for a referral. Referrers share their unique link on several channels such as Facebook, Twitter, LinkedIn, Instagram, Whatsapp and Facebook Messenger. Referrers are then rewarded for spreading the word and getting new customers and subscribers.</p><p><a className='company-link' href='https://www.earlyparrot.com/'>EarlyParrot</a> can be easily integrated on most popular platforms including Google Tag Manager, Shopify, WordPress, Joomla, Magento, Mailchimps, and Drupal…</p>
      <h4>EARLYPARROT SOLUTIONS</h4>
      <ul>
        <li>Any SaaS platform. Have EarlyParrot integrated in your SaaS to turn your users into ambassadors by having them refer new users in exchange for a reward</li>
        <li>Grow your email list by turning your email subscribers into active referrers. Integrates with your existing opt-in form. Automatically reward referrers for other subscribers</li>
        <li>Integrate a referral marketing campaign to your e-commerce platform to get high converting buyers referred to your online store. Automatically sends out coupons as rewards.</li>
      </ul>
      `,
    links: [
      // {
      //   title: 'Early Parrot',
      //   url: 'https://www.earlyparrot.com/'
      // }
    ]
  },
  {
    id: 5,
    slug: 'hana',
    banner: require('../assets/images/projects/hana.png').default,
    title: 'Hana',
    categories: ['web', 'mobile'],
    cover: {
      alt: 'alt',
      relativeUrl: require('../assets/images/projects/hana.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'We design the products from start to finish including Wireframes, Project Architecture, Backend Development, Mobile Apps, and Web Admin.',
    stack: `<ul>
      <li>Flutter</li>
      <li>ReactJS</li>
      <li>Python</li>
      <li>Firebase</li>
      </ul>`,
    market: `<ul>
    <li>Product, Vietnam</li>
    </ul>`,
    shortDescription:
      'Hana is a social Quiz game that allow participants to play with friends with many challenges. In events and challenges, players can compete with opponents by scoring points and tracking them on the Leader board in real-time.',
    description: `<p>Hana is a social Quiz game that allow participants to play with friends with many challenges.</p>
      <p>In events and challenges, players can compete with opponents by scoring points and tracking them on the Leader board in real-time.</p>
      <h4>Main Features:</h4><ul>
      <li>Login with Social Login</li>
      <li>Join different Events, Challenge your friends</li>
      <li>Invite friends to join games and follow each other personal page</li>
      <li>Gold Board (Leader board)</li>
      </ul>`,
    links: [
      {
        title: 'Hana mobile app',
        url: 'https://apps.apple.com/us/app/hana/id1591595926'
      }
    ]
  },
  {
    id: 6,
    slug: 'sterling_homes',
    banner:
      'https://images.unsplash.com/photo-1513694203232-719a280e022f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80',
    title: 'Sterling Homes',
    categories: ['mobile'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://images.unsplash.com/photo-1513694203232-719a280e022f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80',
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development teams join to build the app from Scratch. From the client’s idea, we illustrate on wireframe, build UI/UX on Figma and implement the mobile app.',
    stack: `<ul>
      <li>Flutter</li>
      <li>Firebase</li>
      </ul>`,
    market: `<ul>
    <li>Canada</li>
    </ul>`,
    shortDescription:
      'Introducing the Sterling Homes Edmonton App. Search nearby Sterling homes, browse photos, and see the most up-to-date details about your favourite models. You can also instantly book a Sterling Self-Showing Tour right from your fingertips.',
    description: `<p>Introducing the Sterling Homes Edmonton App. Search nearby Sterling homes, browse photos, and see the most up-to-date details about your favourite models. You can also instantly book a Sterling Self-Showing Tour right from your fingertips.</p>
      <ul>
      <li><strong>INVENTORY:</strong> Search through hundreds of available homes and use detailed filters such as price, home styles, communities, and more.</li>
      <li><strong>MODELS:</strong> View and compare available models including front attached, laned homes, duplexes, and townhomes to find the perfect floorplan for you.</li>
      <li><strong>SHOWHOMES:</strong> Check out the many show homes located throughout the Edmonton and surrounding area.</li>
      <li><strong>STERLING SELF-SHOWINGS:</strong> Book a self-showing tour instantly right from the app Sterling Homes, our mission is to provide the opportunity for affordable homeownership without compromise. No matter what you and your families’ needs are, Sterling Homes can help you find the perfect home to suit your lifestyle. Over the last 20 years, Sterling Homes has become one of Alberta's most popular builders. When you build with Sterling, know we'll deliver a timely, well-built home you can enjoy for years to come.</li>
      </ul>`,
    links: [
      // {
      //   title: 'Sterling Homes mobile app',
      //   url: 'https://apps.apple.com/us/app/sterling-homes/id1554704038'
      // }
    ]
  },
  {
    id: 7,
    slug: 'gcreno',
    banner: require('../assets/images/projects/gcreno.png').default,
    title: 'GCReno',
    categories: ['mobile'],
    cover: {
      alt: 'alt',
      relativeUrl:require('../assets/images/projects/gcreno-thumb.jpeg').default,
      absoluteUrl: '../assets/images/projects/gcreno.png'
    },
    proposeSolutions:
      'Our development teams join to build the app from Scratch. From the client’s idea, we illustrate on wireframe, build UI/UX on Figma and implement the mobile app.',
    stack: `<ul>
      <li>Kotlin</li>
      <li>Swift</li>
      </ul>`,
    market: `<ul>
    <li>Canada</li>
    </ul>`,
    shortDescription:
      'It’s time to part ways with your spreadsheets Manage your team, projects and clients anytime, on any device.',
    description: `<p>GC Reno app: It’s time to part ways with your spreadsheets Manage your team, projects and clients anytime, on any device:</p>
      <ul>
        <li>Instant lead access tool</li>
        <li>Single entry estimates</li>
        <li>Project optimization</li>
        <li>Customized professional estimates</li>
        <li>Clear task delegation</li>
        <li>Project deficiencies and to-dos management</li>
        <li>Remote document access</li>
        <li>Ongoing team communication</li>
        <li>Client & subcontractor directories</li>
        <li>Exclusive access to RenoQuotes.com and WalkThru app</li>
      </ul>`,
    links: [
      // {
      //   title: 'GCReno mobile app',
      //   url: 'https://apps.apple.com/us/app/gcreno/id1451744371'
      // }
    ]
  },
  {
    id: 8,
    slug: 'xtra_food_club',
    banner:
      'https://images.unsplash.com/photo-1423483641154-5411ec9c0ddf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    title: 'XtraFoodClub',
    categories: ['mobile'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://images.unsplash.com/photo-1423483641154-5411ec9c0ddf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development teams join to keep working on the current source code, adding new features, improve the performance.',
    stack: `<ul>
      <li>Flutter</li>
      <li>Firebase</li>
      </ul>`,
    market: `<ul>
    <li>Canada</li>
    </ul>`,
    shortDescription:
      'XtraFoodClub is a unique and innovative mobile marketplace platform that enables you to share food with other individuals and households in your area.',
    description: `<p>XtraFoodClub is a unique and innovative mobile marketplace platform that enables you to share food with other individuals and households in your area. Be environmentally conscious and community-minded. Save your money and our planet. </p>`,
    links: [
      // {
      //   title: 'XtraFoodClub mobile app',
      //   url: 'https://apps.apple.com/us/app/xtrafoodclub/id1535814095'
      // }
    ]
  },
  {
    id: 9,
    slug: 'free_agent',
    banner:
      'https://images.unsplash.com/photo-1457305237443-44c3d5a30b89?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1174&q=80',
    title: 'FreeAgent Hub Business',
    categories: ['mobile'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://images.unsplash.com/photo-1457305237443-44c3d5a30b89?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1174&q=80',
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development teams join to build the app from Scratch. From the client’s idea, we illustrate on wireframe, build UI/UX on Figma and implement the mobile app.',
    stack: `<ul>
      <li>Flutter</li>
      <li>Firebase</li>
      </ul>`,
    market: `<ul>
    <li>Canada</li>
    </ul>`,
    shortDescription:
      'Earn income or save money on services on FreeAgentHub platform. FreeAgentHub is a multi-service on-demand marketplace platform. It is free to download and to sign up as a free agent or a customer.<br />Sign up as a free agent, select the services you want to provide, set your own schedule and rates.<br />',
    description: `
      <p>Earn income or save money on services on FreeAgentHub platform. FreeAgentHub is a multi-service on-demand marketplace platform. It is free to download and to sign up as a free agent or a customer.</p>
      <p>Sign up as a free agent, select the services you want to provide, set your own schedule and rates.</p>
      <p>Alternatively, you can download FreeAgentHub customer app – it is a great way to find local service providers, schedule appointments, and pay for services quickly and easily.</p>
      <p>It is free to join FreeAgentHub platform as service provider or customer. Download both apps today and earn extra income or save money on services.</p>
    `,
    links: [
      // {
      //   title: 'FreeAgent Hub Business mobile app',
      //   url: 'https://apps.apple.com/us/app/freeagent-hub-business/id1167053414'
      // },
      // {
      //   title: 'FreeAgent Hub mobile app',
      //   url: 'https://apps.apple.com/us/app/freeagent-hub/id1167051219'
      // }
    ]
  },
  {
    id: 10,
    slug: 'nwt_water_operator',
    banner:
      'https://images.unsplash.com/photo-1620316521710-ef75e5642181?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
    title: 'NWT Water Operator',
    categories: ['mobile'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://images.unsplash.com/photo-1620316521710-ef75e5642181?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development teams join to build the app from Scratch. From the client’s idea, we illustrate on wireframe, build UI/UX on Figma and implement the mobile app.',
    stack: `<ul>
      <li>Flutter</li>
      <li>Firebase</li>
      </ul>`,
    market: `<ul>
    <li>Canada</li>
    </ul>`,
    shortDescription:
      'GNWT Department of Municipal and Community Affairs in Canada, this application is designed to assist NWT community works for staff with water treatment plant operations.',
    description: `
    <p>GNWT Department of Municipal and Community Affairs in Canada, this application is designed to assist NWT community works for staff with water treatment plant operations. Included in this application are:</p>
    <ul>
      <li>Common sampling procedures used in NWT water treatment plants.</li>
      <li>Batch chlorination calculator.</li>
      <li>Maintenance lists for the types of water treatment plants used in the NWT.</li>
      <li>Drinking water sampling requirements as defined by GNWT Health and Social Services.</li>
      <li>Information and limits of parameters typically found in drinking water.</li>
    </ul>
    <p>Once download, the application does not require an active internet connection. This application does not require a log-in or any personal information.</p>

    `,
    links: [
      // {
      //   title: 'NWT Water Operator mobile app',
      //   url: 'https://apps.apple.com/us/app/nwt-water-operator/id1559077338'
      // }
    ]
  },
  {
    id: 11,
    slug: 'nwt_walking_tour',
    banner:
      'https://images.unsplash.com/photo-1512617835784-a92626c0a554?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
    title: 'NWT Walking Tour',
    categories: ['mobile'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://images.unsplash.com/photo-1512617835784-a92626c0a554?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1074&q=80',
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development teams join to build the app from Scratch. From the client’s idea, we illustrate on wireframe, build UI/UX on Figma and implement the mobile app.',
    stack: `<ul>
      <li>Flutter</li>
      <li>Firebase</li>
      </ul>`,
    market: `<ul>
    <li>Canada</li>
    </ul>`,
    shortDescription:
      'The Government of the Northwest Territories presents: the NWT Walking Tour App. This free app provides northern residents and visitors a chance to learn about the Northwest Territories.',
    description: `
      <p>Learn about the Northwest Territories through fun, interactive walking tours.</p>
      <p>The Government of the Northwest Territories presents: the NWT Walking Tour App. This free app provides northern residents and visitors a chance to learn about the Northwest Territories.</p>
      <p>Beginning with geological interpretive walks, an expanding selection of tours will tempt you to look at things in new ways as you explore the territory. Real-time maps keep you on-track as you explore the trails.</p>
      <p>Looking to save data? The tours can be downloaded and used offline.</p>
    `,
    links: [
      // {
      //   title: 'NWT Walking Tour mobile app',
      //   url: 'https://apps.apple.com/ca/app/nwt-walking-tour/id1519624586'
      // }
    ]
  },
  {
    id: 12,
    slug: 'chakra_oracle',
    banner:
      'https://image.jimcdn.com/app/cms/image/transf/none/path/s2a291f43ce10b74e/backgroundarea/i4fe5a5b476eda5b4/version/1635351089/image.png',
    title: 'Chakra Oracle',
    categories: ['mobile'],
    cover: {
      alt: 'alt',
      relativeUrl:
        'https://image.jimcdn.com/app/cms/image/transf/none/path/s2a291f43ce10b74e/backgroundarea/i4fe5a5b476eda5b4/version/1635351089/image.png',
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development teams join to build the app from Scratch. From the client’s idea, we illustrate on wireframe, build UI/UX on Figma and implement the mobile app.',
    stack: `<ul>
      <li>Flutter</li>
      <li>Firebase</li>
      </ul>`,
    market: `<ul>
    <li>Canada</li>
    </ul>`,
    shortDescription: 'Chakrify Yourself!',
    description: `
      <p>Chakrify Yourself! The POWER of 8 Chakra Oracle Cards has it all:</p>
      <ul>
        <li>Energy healing, divination, affirmations, chakra balancing and intuitive guidance all in one.</li>
        <li>The app identifies which chakra needs balancing and includes tips on healing.</li>
        <li>Each card is an affirmation to attract happiness and success.</li>
        <li>The oracle offers you intuitive guidance for what is your next step with sprinkles of Law of Attraction techniques.</li>
        <li>Unique paintings and illustrations by an energy healing artist combined with soul healing advice from a spiritual medium.</li>
        <li>Features the Soul Star chakra healing on the back of each card for an extra energy boost!</li>
        <li>Features simple yet effective spreads to try or just go with your chakra card of the day.</li>
        <li>May you find clarity, wisdom, motivation and joy with this chakra oracle card app meant to uplift your spirit and increase your vibration.</li>
      </ul>
    `,
    links: [
      // {
      //   title: 'Chakra Oracle mobile app',
      //   url: 'https://apps.apple.com/us/app/chakra-oracle/id1523318950'
      // }
    ]
  },
  {
    id: 13,
    slug: 'scenic',
    banner: require('../assets/images/projects/scenic.png').default,
    title: 'Scenic',
    categories: ['web'],
    cover: {
      alt: 'alt',
      relativeUrl:require('../assets/images/projects/scenic.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development team contributes work and ideas to add new values and features to the existing system.',
    stack: `<ul>
      <li>.NET</li>
      <li>GraphQL</li>
      <li>Angular</li>
      </ul>`,
    market: `<span>Worldwide</span>`,
    shortDescription: `Scenic is a is a software solution that covers and handles all the business
      processes involved in the property development business, starting from
      property / land acquisition, going through the entire property design,
      planning, development, project management and up to promise of sales and
      contract management.`,
    description: `
      <p>Scenic is a is a software solution that covers and handles all the business
      processes involved in the property development business, starting from
      property / land acquisition, going through the entire property design,
      planning, development, project management and up to promise of sales and
      contract management. Everything else in between and in parallel is handled
      by Scenic in an easy-to-use environment, allowing all your members of staff,
      from different departments to centrally collaborate on a system that could
      either run on the cloud, or on your premises.</p>
      <p>The solution is modular, allowing you to give different members of staff in
      different positions, different privileges according to their roles. The following
      is a general view of the modules that make up Scenic for Property
      Developers:</p>
      <ul>
        <li>Property Acquisition Opportunities Tracking.</li>
        <li>Architectural Project Management Tool (with direct integration with PA for Malta).</li>
        <li>Real Estate Agency CRM.</li>
        <li>On-Site Construction and Finishing Project Management.</li>
        <li>Health & Safety.</li>
        <li>Procurement Management.</li>
        <li>Administration Management.</li>
      </ul>
      <p>With Scenic, all your departments are looking at the latest data and you can
      rest assured that everyone is informed with the latest changes. Gone are the
      days when the architects’ office change a 3-bedroom unit into a 2-bedroom
      one, while your sales team is still marketing the unit with old information,
      leading to a promise of sale that is not in line with what you will be delivering.
      Such mistakes could lead into significant costs and losses and Scenic is there
      to save the day.</p>
      <div style="text-align:center;margin-top: 20px">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/im1D5_l5WVU?start=7" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div>
    `,
    links: [
    ]
  },
  {
    id: 14,
    slug: 'iyyara',
    banner: require('../assets/images/projects/iyyara_banner.png').default,
    title: 'Iyyara',
    categories: ['mobile', 'web'],
    cover: {
      alt: 'alt',
      relativeUrl:require('../assets/images/projects/iyyara_thumb.jpg').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development team contributes work and ideas to add new values and features to the existing system.',
    stack: `<ul>
      <li>Flutter</li>
      <li>Meteor.js</li>
      <li>Node.js</li>
      </ul>`,
    market: `<span>Singapore</span>`,
    shortDescription: 'Iyyara is an app for halal food. It informs users about the available halal food options according to their halal preferences. This includes retail, restaurants, online sellers and more!.',
    description: `
      <p>Iyyara is an app for halal food. It informs users about the available halal food options according to their halal preferences. This includes retail, restaurants, online sellers and more!.</p>
      <p>Businesses are first listed in Iyyara through user submissions and will be tagged 'user-generated'. If these businesses choose to provide verification documents to Iyyara, their listing will then be tagged 'Muslim Owned' or 'Certified Halal' or other more accurate halal classification if fit.</p>
    `,
    links: [
    ]
  },
  {
    id: 16,
    slug: 'mark',
    banner: require('../assets/images/projects/mark.png').default,
    title: 'Mark Platform',
    categories: ['web', 'mobile' ],
    cover: {
      alt: 'alt',
      relativeUrl:require('../assets/images/projects/mark.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our development teams joined to build the app from scratch. From the client’s idea, we illustrate wireframes, build UI/UX on Figma and implement multi-platform application.',
    stack: `<ul>
      <li>Python</li>
      <li>React.js</li>
      <li>Flutter</li>
      </ul>`,
    market: `<span>Singapore</span>`,
    shortDescription: 'The Mark Platform is a review and discovery platform for interior designers in Singapore.',
    description: `
      <p>The Mark Platform is a review and discovery platform for interior designers in Singapore.</p>
      <p>The “Mark Platform” refers to the collection of websites, mobile apps and backend applications that makes the platform possible as a business. There are three main parts: the Mark Web, the Mark Members App, and the Mark App.</p>
      <p>On one hand, Mark Platform is the perfect place for interior designers to freely share their designs and ideas. On the other hand, homeowners who are looking for a redesign in their accommodation can contact designer with adequate style.</p>
    `,
    links: [
    ]
  },
  {
    id: 17,
    slug: 'easy-cambodia',
    banner: require('../assets/images/projects/easy-cambodia.png').default,
    title: 'Easy Cambodia',
    categories: ['web', 'mobile' ],
    cover: {
      alt: 'alt',
      relativeUrl:require('../assets/images/projects/easy-cambodia-app-icon.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'We are participating in this project with the goal of improving performance by converting the native mobile app to use Flutter.',
    stack: `<ul>
      <li>Flutter</li>
      <li>PHP</li>
      <li>Twig</li>
      <li>MySQL</li>
      <li>Symfony Framework</li>
      </ul>`,
    market: `<span>Cambodia</span>`,
    shortDescription: 'Easy Cambodia" is a unique project aimed at introducing and guiding tourists when they visit Cambodia.',
    description: `
      <p>"Easy Cambodia" is a unique project aimed at introducing and guiding tourists when they visit Cambodia. This project has developed both a web version and a mobile application, providing a diverse and convenient experience for all types of travelers.</p>
      <p>With Easy Cambodia, tourists can easily learn about famous landmarks, historical sites, culture, and even local secrets that are a must-see. The project offers detailed information on tourist attractions, delicious cuisines, as well as entertainment activities in the area.</p>
      <p>The web version of Easy Cambodia allows travelers to access information easily from any internet-connected device. Meanwhile, the mobile version enables them to carry useful information throughout their journey, making planning and relaxation more convenient.</p>
      <p>With Easy Cambodia, tourists will explore Cambodia in a more exciting and comfortable way than ever before, helping them enjoy memorable experiences during their journey in this beautiful country.</p>
    `,
    links: [
      {
        title: 'iOS app',
        url: 'https://apps.apple.com/us/app/easy-cambodia/id6447607913'
      },
      {
        title: 'Android app',
        url: 'https://play.google.com/store/apps/details?id=com.easy.combodia'
      }
    ]
  },
  {
    id: 18,
    slug: 'liberwood',
    banner: require('../assets/images/projects/liberwood-banner.png').default,
    title: 'Liberwood',
    categories: ['web'],
    cover: {
      alt: 'alt',
      relativeUrl:require('../assets/images/projects/liberwood-banner.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'We are participating in a project with the task of building a website interface based on the existing design, ensuring a high user experience, as well as being SEO-friendly and fast-loading.',
    stack: `<ul>
      <li>Next.js</li>
      <li>React.js</li>
      <li>TailwindCSS</li>
      </ul>`,
    market: `<span>Vietnam</span>`,
    shortDescription: 'Visitors to the liberwood.com Ecommerce website will find themselves immersed in a world of artisanal craftsmanship and boundless creativity.',
    description: `
      <p>Visitors to the liberwood.com Ecommerce website will find themselves immersed in a world of artisanal craftsmanship and boundless creativity. The platform showcases a wide array of categories, from meticulously crafted wooden items to intricately designed art pieces, not to mention exclusive collections meticulously crafted by the store itself. Beyond these offerings, the website proudly presents a gallery of completed projects. As you explore liberwood.com, you'll gain a unique perspective on the fusion of fine craftsmanship and innovation, all conveniently available in one place.</p>
    `,
    links: [
      {
        title: 'Website',
        url: 'https://liberwood.com/'
      }
    ]
  },
  {
    id: 19,
    slug: 'ankle-sprain',
    banner: require('../assets/images/projects/ankle.png').default,
    title: 'Ankle Sprain',
    categories: ['web', 'mobile'],
    cover: {
      alt: 'Ankle Sprain',
      relativeUrl:require('../assets/images/projects/ankle-cover.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'We are participating in a project with the task of building a website and mobile app interface based on the existing design, ensuring a high user experience, as well as being SEO-friendly and fast-loading.',
    stack: `<ul>
      <li>Next.js</li>
      <li>React.js</li>
      <li>TailwindCSS</li>
      <li>Flutter</li>
      <li>Nestjs</li>
      <li>MongoDB</li>
      </ul>`,
    market: `<span>Canada</span>`,
    shortDescription: 'Ankle Sprain is a survey application that helps monitor patients (people with ankle injuries) after they finish treatment at the hospital.',
    description: `
      <p>Ankle Sprain is a survey application that helps monitor patients (people with ankle injuries) after they finish treatment at the hospital. From the survey results, the application will make recommendations to help. The patient recovers quickly. The application was created to replace the practice of giving each patient a piece of paper so they can self-monitor their recovery status.</p>
    `,
    links: [
    ]
  },
  {
    id: 20,
    slug: 'sofia-platform',
    banner: require('../assets/images/projects/proj13.png').default,
    title: 'Sofia Platform',
    categories: ['ai'],
    cover: {
      alt: 'Ankle Sprain',
      relativeUrl:require('../assets/images/projects/proj13-cover.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'We are participating in a project with the task of building a website and mobile app interface based on the existing design, ensuring a high user experience, as well as being SEO-friendly and fast-loading.',
    stack: `<ul>
      <li>Vite framework (JS)</li>
      <li>Tailwind CSS (HTML, CSS)</li>
      <li>NodeJS</li>
      <li>Laravel framework (PHP 8+)</li>
      <li>OpenAI - StableDiffusion (Python)</li>
      <li>AWS (Cloud)</li>
      </ul>`,
    market: `<span>Worldwide</span>`,
    shortDescription: '<p>Sofia Platform is a system built on top of the best AI platforms available today, such as OpenAI, Stable Diffusion</p>',
    description: `
      <p>
      Sofia Platform is a system built on top of the best AI platforms available today, such as OpenAI, Stable Diffusion. <br/>
        <ul>
          <li>
            Users can easily create image, text, and audio content according to their fields and work needs.
          </li>
          <li>
            Helpfully save costs, time, and significantly increase work efficiency
            compared to Chat GPT because Sofia Platform provides a rich library of
            Prompts from various fields, allowing users to freely choose. The output
            results are finely tuned to meet customer requirements.
          </li>
        </ul>
      </p>
    `,
    links: [
    ]
  },
  {
    id: 21,
    slug: 'terminal-booking-system',
    banner: require('../assets/images/projects/proj14.png').default,
    title: 'Terminal Booking System',
    categories: ['web'],
    cover: {
      alt: 'Terminal Booking System',
      relativeUrl:require('../assets/images/projects/proj14-cover.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'We are involved in a project that involves building a mobile application frontend, adding features, ensuring high user experience and fast loading.',
    stack: `<ul>
      <li>Angular</li>
      <li>.NET</li>
      <li>GraphQL</li>
      </ul>`,
    market: `<span>Malta</span>`,
    shortDescription: `<p>This project involves the design and implementation of a Booking System aimed at
streamlining the booking process for trailers and other cargo onto export voyages.</p>`,
    description: `
      <p>
        This project involves the design and implementation of a Booking System aimed at
        streamlining the booking process for trailers and other cargo onto export voyages. The
        system, developed for Gollcher Company Ltd, is a robust web application enabling
        seamless interaction between clients and system administrators. It automates booking
        requests, approvals, voyage management, and reporting while providing tailored role-
        based access to different user groups.
      </p>
    `,
    links: [
    ]
  },
  {
    id: 22,
    slug: 'ongo',
    banner: require('../assets/images/projects/proj15.png').default,
    title: 'ONGO',
    categories: ['mobile'],
    cover: {
      alt: 'Terminal Booking System',
      relativeUrl:require('../assets/images/projects/proj15-cover.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'We are involved in a project that involves developing the processing system, adding new features, building the website interface, ensuring high user experience and fast loading.',
    stack: `<ul>
      <li>Flutter</li>
      </ul>`,
    market: `<span>Philippines</span>`,
    shortDescription: `<p>ONGO.PH offers a valuable fraud-resistant advertising service via its Smart Tablets installed on vehicles' seats' headrest.</p>`,
    description: `
      <p>
        An industry innovation to traditional Out-of-Home advertising, thus reaching new levels of mobility and impressions. OnGo software with unique technological features and loaded onto our customized tablets, OnGo delivers REAL-TIME and MEASURABLE advertising that helps brands penetrate their desired target markets and audiences.
        <br/>
        <ul>
          <li>
            ONGO.PH offers a valuable fraud-resistant advertising service via its Smart Tablets installed on vehicles' seats' headrest.
          </li>
          <li>
            ONGOS mobile digital advertising tablets installed in vehicles, delivers location targeted image or video advertising throughout the country of Philippines.
          </li>
        </ul>
        <br/>
      </p>
    `,
    links: [
    ]
  },
  {
    id: 22,
    slug: 'buzlin',
    banner: require('../assets/images/projects/proj16.jpg').default,
    title: 'BUZLIN',
    categories: ['mobile'],
    cover: {
      alt: 'Terminal Booking System',
      relativeUrl:require('../assets/images/projects/proj16-cover.png').default,
      absoluteUrl: ''
    },
    proposeSolutions:
      'Our team is primarily responsible for developing the project, adding new features, fixing bugs, and releasing new versions.',
    stack: `<ul>
      <li>Flutter</li>
      <li>PHP</li>
      <li>Firebase</li>
      </ul>`,
    market: `<span>Philippines, Canada</span>`,
    shortDescription: `<p>Buzlin is a multi-service platform that allows users to book appointments and connect with service providers conveniently.</p>`,
    description: `
      <p>
        Buzlin – A Comprehensive Multi-Service Platform Connecting Users with Service Providers.
        <br/>
        <ul>
          <li>
            Buzlin is an all-in-one multi-service platform designed to seamlessly connect users with service providers across various industries. With an intuitive interface and a streamlined booking process, Buzlin ensures maximum convenience for individuals and small businesses looking to optimize their service management.
          </li>
          <li>
           In addition to the core Buzlin app, the ecosystem includes BuzRyde Driver, an application tailored for drivers to manage their trips, track earnings, and navigate routes efficiently. This app enhances the driver experience by providing real-time trip details, customer insights, and optimized routing.
          </li>
          <li>
            Buzlin prioritizes user data security by implementing advanced encryption standards, ensuring that personal information remains fully protected. The platform adheres to strict data privacy policies, collecting only essential information to enhance user experience while maintaining confidentiality.
          </li>
          <li>
            With a mission to provide fast and efficient connections, Buzlin not only saves users time but also fosters a modern digital ecosystem that caters to the diverse needs of both customers and service providers.
          </li>
        </ul>
        <br/>
      </p>
    `,
    links: [
    ]
  }
]
